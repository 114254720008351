<template>
    <div class=" pt-4 mb-footer border-radius-0 border-0"
                style="background: transparent;"
              >
                <div class="logo-wrapper text-center mb-2">
                    <img :src="'/'+this.getSiteProps('login.logo')" alt="logo">
                </div>
                <div class="login-main-wrapper" style="width: 535px;">
                  <div class="login-wrapper">
                    <div class="card-header">
                      <h3>{{ this.getSiteProps('login.club_login_title') || 'Club Login' }}</h3>
                    </div>
                    <div class="card card-body pt-3 pb-3">
                      <form
                        method="post"
                        id="loginForm"
                        @submit.prevent="checkLogin"
                      >
                        <div class="form-group">
                          <label class="form-label">{{ this.getSiteProps('login.club_login_userName') || 'Email or Club Membership ID' }}</label>
                          <div class="input-group mb-1">
                            <div class="input-group-append">
                              <div class="input-group-text">
                                <i class="fa fa-user"></i>
                              </div>
                            </div>
                            <input
                              type="text"
                              v-model="email"
                              :placeholder=" this.getSiteProps('login.club_login_userName') || 'Email or Club Membership ID'"
                              v-bind:class="{ 'is-invalid': isActiveEmail }"
                              name="email"
                              class="form-control"
                            />
                          </div>
                          <div v-bind:class="{ error: isActiveEmail }">
                            {{ errors_email }}
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="form-label">Password</label>
                          <div class="input-group mb-1">
                            <div class="input-group-append">
                              <div class="input-group-text">
                                <i class="fas fa-key"></i>
                              </div>
                            </div>
                            <input
                              :type="passwordFieldType"                             
                              v-model="password"
                              class="form-control"
                              v-bind:class="{ 'is-invalid': isActivePassword }"
                              placeholder=" Password"
                              name="password"
                            />
                            <div class="showhide">
                              <!-- <i class="fas fa-eye-slash"></i> -->
                            <span type="password"  @click="switchVisibility" ><i v-bind:class="{ 'fa-eye-slash': isActive}" class="far fa-eye"></i></span>
                            </div>
                          </div>
                          <div v-bind:class="{ error: isActivePassword }">
                            {{ errors_password }}
                          </div>
                        </div>
                        <div class="form-button">
                          <b-button
                            @click="forgotPassword()"
                            class="btn-primary btn-transparent mr-2"
                            >I forgot my password
                          </b-button>
              
                          <b-button type="submit" class="btn-primary btn-red" :disabled="loading">
                            <i class="fa fa-circle-notch fa-spin" v-if="loading"></i>
                            <span v-else>Login</span>
                          </b-button>
                        </div>
                         <!-- <hr>
                        <b-button style="width:100%;background-color: #05213e;" type="submit" class="btn-primary btn-red" disabled>                            
                            <span>Log in with SSO </span>
                          </b-button> -->
                          
                      </form>
                    </div>
                  </div>
                  <div class="row">
                  <div class="col-md-6">
                  <div class="login-register mb-3 login-links-wrap">
                    <div class="card-header">
                      <h3>Do you have a Member Account?</h3>
                    </div>
                    <div class="card card-body mb-0">
                      <p class="mb-2">If you have a member user account, you can login with this link</p>
                      <b-button class="btn-primary btn-blue mb-2 p-2" @click="memberLogin" :style="{ fontSize: subdomain == 'waterski' && '11px' }"
                        >Member Login</b-button
                      >
                    </div>
                  </div>
                  </div>
                  <div class="col-md-6">
                  <div class="login-register login-links-wrap">
                    <div class="card-header">
                      <h3>Do not have an account?</h3>
                    </div>
                    <div class="card card-body mb-0">
                      <p class="mb-2">
                        If you are not registered yet, create your account
                        easily
                      </p>
                      <div class="form-button mb-2">
                      <b-button @click="register('club_register_link')"  :class="['btn-primary', 'btn-blue',{ 'float-sm-right': subdomain !== 'waterski' }]" :style="{ fontSize: subdomain == 'waterski' && '11px' }">
                        {{this.getSiteProps('login.club_register_button')}}
                      </b-button>
                        <b-button @click="register('campski_school_register_link')" v-if="this.getSiteProps('login.campski_school_register_button')" class="btn-primary btn-blue p-0 float-sm-right" style="width:110px;font-size: 11px;">
                          Camp/School Registration
                        </b-button>
                      </div>
                    </div>
                    </div>
                  </div>
                    </div>
                </div>     
                  <clubfooter/>
              </div>
</template>
<style scoped>
.login-links-wrap h3 {
  font-size: 15px;
}
.login-links-wrap .card-header, .login-links-wrap .card-body {
  padding: 5px 15px;
}
.login-links-wrap p{
  font-size: 13px;
}
.login-links-wrap button{
  height: 35px;
  font-size: 14px;
}
.login-section-wrapper {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  padding: 15px 0;
  background-color: #f2f2f2;
}
.login-img {
  width: 100%;
  height: 100vh;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: top;
  object-position: top;
}
.dashboard-qustion {
    text-align: center;
    margin-bottom: 0 !important;
    padding: 20px 0 17px 0;
}
#loginForm{
  width: 499px;
}
</style>
<script>
import clubfooter from "./components/Footer.vue"
import axios from "axios";
import moment from "moment";
export default {
  name: "clubLogin",
  components : {clubfooter},
  data() {
    return {
      loading: false,
      email: "",
      password: "",
      errors_email: "",
      errors_password: "",
      error_class: "",
      isActiveEmail: false,
      isActivePassword: false,
      isActive: false,
      passwordFieldType: "password",
      register_links: {}
    };
  },
  methods: {
    forgotPassword: function() {
      window.open("/club/forgotpassword", "_self");
    },
    switchVisibility() {
      this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";      
        this.isActive = !this.isActive;
    },
    register: function(linkType) {
      this.register_links = this.getSiteProps("login");
      const link = this.register_links[linkType];
      window.location.href = link;
    },
    checkLogin: function() {
      if (this.email != "" && this.password != "") {
        this.isActiveEmail = false;
        this.isActivePassword = false;
        this.errors_email = "";
        this.errors_password = "";
        this.loading = true;
        axios
          .post(this.basePath + "api/club/login", {
            email: this.email,
            password: this.password,
            type:'club'
          })
          .then((response) => {
            if (response.data.status == "success") {
              if(response.data.data.member != undefined)
              {
                localStorage.setItem("membertoken", response.data.data.token);
                localStorage.setItem("member_type", 7);
                localStorage.setItem(
                    "member",
                    JSON.stringify(response.data.data.member)
                );
              }
              else
              {
                localStorage.setItem("clubToken", response.data.data.token);
                localStorage.setItem(
                    "team",
                    JSON.stringify(response.data.data.team)
                );
                localStorage.setItem("ssoToken", response.data.data.sso_token);
                let sso_cookie = response.data.data.sso_cookie;
                //this.cookie.set(sso_cookie.name, sso_cookie.token, sso_cookie.time);
                let d = new Date();
                d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
                let expires = "expires=" + d.toUTCString();
                document.cookie =
                    sso_cookie.name+"=" + sso_cookie.token + ";" + expires + ";domain="+sso_cookie.domain+";path=/";
              }
              localStorage.setItem("time", moment().unix());

              if(this.returnurl !='' && this.returnurl != undefined){
                window.location.href=this.returnurl;
              }else if(response.data.data.active == 1){
                if(this.subdomain=='alliancefastpitch')
                {
                  this.$router.push({ path: "/team/dashboard" });
                }
                else if(response.data.data.member != undefined)
                {
                  this.$router.push({ path: "/member/dashboard" });
                }
                else{
                  this.$router.push({ path: "/club/dashboard" });
                }
                
              }else{
                if(response.data.data.member != undefined){
                  this.$router.push({ path: "/member/dashboard" });
                }
                else{
                  this.$router.push({ path: "/club/renew" });
                }
              }

            } else {
              this.errors_password =
                "These credentials do not match our records.";
              this.isActivePassword = true;
              this.loading = false;
            }
          })
          .catch((error) => {
            this.loaded = true;
            if (error.response.status === 422) {
              this.errors = error.response.data.errors || {};
            }
            this.loading = false;
          });
      } else {
        if (!this.email) {
          this.errors_email = "The email field is required.";
          this.isActiveEmail = true;
        } else {
          this.errors_email = "";
          this.isActiveEmail = false;
        }
        if (!this.password) {
          this.errors_password = "The password field is required.";
          this.isActivePassword = true;
        } else {
          this.errors_password = "";
          this.isActivePassword = false;
        }
      }
    },
    memberLogin(){
      this.$router.push("/member/login")
    }
  },
  beforeMount(){
    if(localStorage.clubtoken != undefined) this.$router.push("/club/dashboard");
    this.returnurl=this.$route.query.returnurl;
  }
};
</script>
